import Button from "https://framer.com/m/Button-SYeU.js@UmtRQhfHPGWjDs2d5goq"
import PhaseButtons from "https://framer.com/m/Phase-Buttons-E8g6.js@OHf42qodoBbbxVX35lKX"
import CardButton from "https://framer.com/m/CardButton-MQUf.js@5ZELAesOgKVRO9V8YCJO"
import { CaretLeft } from "phosphor-react"
import styles from './button.module.css'

const PrimaryButton = ({ children, loading, onClick, width, style, icon, fullWidth, disabled }) => {

    return <Button
        icon={icon}
        style={{ width: fullWidth ? '100%' : width, ...style, cursor: disabled ? "auto" : "pointer", opacity: disabled ? 0.5 : 1 }}
        tap={disabled ? null : onClick}
        variant={loading ? 'primary-loading' : icon ? 'primary-icon' : 'primary'}
        title={children}>

    </Button>
}

const SecondaryButton = ({ children, loading, onClick, icon, width, style, fullWidth }) => {
    return <Button style={{ width: fullWidth ? '100%' : width, ...style }} tap={onClick} icon={icon} variant={loading ? 'secondary-loading' : icon ? 'secondary-icon' : 'secondary'} title={children}></Button>
}

const LinkButton = ({ children, loading, onClick, icon, width, style }) => {
    return <Button style={{ width, ...style }} tap={onClick} icon={icon} variant="Variant 7" title={children}></Button>
}

const BackButton = ({ children, onClick, style }) => {
    return <div onClick={onClick} className={styles.backButton} style={style}>
        <CaretLeft weight="bold" size={24} />
        {children ?? "Back"}
    </div>
}

const TextButton = ({ children, onClick, style }) => {
    return <div onClick={onClick} className={styles.backButton} style={style}>
        {children ?? "Back"}
    </div>
}



export { PrimaryButton, SecondaryButton, LinkButton, PhaseButtons, CardButton, BackButton, TextButton }