import Phosphor from "https://framer.com/m/Phosphor-9Q9Y.js@qyebYqHqVZaLb5Up06qa"
import styles from "./list.module.css"
const ListAction = (props) => {
    const { color } = props
    const disabled = props?.disabled || props?.variant === 'disabled'

    const defaultColour = disabled ? "black" : "#8E51C8"

    return <div className={styles.listAction} onClick={!disabled ? props?.tap : null} gap={2} style={{ cursor: "pointer", opacity: disabled ? 0.5 : 1, display: "flex", alignItems: "center", padding: props?.noPadding ? "" : "8px" }} >
        <div xs={4} style={{ marginRight: "12px" }}>
            <div style={{ padding: "4px", width: "32px", height: "32px", position: "relative", borderRadius: "32px", overflow: "hidden", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div style={{ background: color ?? defaultColour, opacity: 0.1, width: "32px", height: "32px", position: "absolute", top: 0, left: 0 }}></div>
                {typeof props?.icon === 'string' ?
                    <Phosphor name={props?.icon} style={{ width: "18px", height: "18px" }} color={color ?? defaultColour}></Phosphor> : <props.icon style={{ color: color ?? defaultColour }} />}
            </div>
        </div>
        <div style={{ lineHeight: "18px" }}>
            <div style={{ fontWeight: "500", color: color ?? defaultColour, margin: 0 }}>{props.title}</div>
            <div style={{ color: color ?? defaultColour, fontSize: "14px", opacity: 0.8 }}>{props?.subtitle}</div>
        </div>
    </div >

}



export { ListAction }