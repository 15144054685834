import {Checkbox, Grid} from "@geist-ui/core"
import Card from "../card"
import styles from './table.module.css'
import EmptyState from "../empty-state"
import Toolbar from "./toolbar"
import Footer from "./footer"
import Skeleton from "react-loading-skeleton"


const Row = ({ item, selectMode, onClick, keyArray, selectableColumn, overrides, isSelected, user }) => {
    const cursorConfig = "normal"
    return <tr  className={styles.row} styles={{ cursor: cursorConfig }}>
        {keyArray.map((key, index) => {
            if (key === selectableColumn && selectMode) {
                return <td className={styles.cell} key={key}><Checkbox scale={2} onClick={onClick} initialChecked={isSelected}></Checkbox></td>
            }
            const value = overrides?.[key]?.(item, user) ?? item[key]
            return <td onClick={(index === 1 && onClick && !selectMode) ? onClick : null} className={styles.cell} key={key}>{value}</td>
        })}
    </tr>
}


const EmptyTableBody = () => {
    return <EmptyState title='No Data'></EmptyState>
}


const Body = ({ data, keys, overrides, onClick, selectModeData, selectableColumn = "icon", user }) => {

    const { totalSelected, selectMode, checkForMembership, addToSelection, removeFromSelection } = selectModeData ?? {}

    if (!keys) return "Keys Missing"

    if (!data) {
        const loadingData = Array(10).fill({}).map(() => { const o = {}; Object.keys(keys).forEach(key => { o[key] = <Skeleton></Skeleton> }); return o })
        return <Body data={loadingData} keys={keys}></Body>
    }

    const keyArray = Object.keys(keys)

    return <div className={styles.tableBody}>
        <table className={styles.table}>
            <thead>
                <tr>
                    {keyArray.map(key => {
                        return <th className={styles.cell} key={key}>{keys[key]}</th>
                    })}
                </tr>
            </thead>
            <tbody>
                {data?.map((item, index) => {
                    const isSelected = selectMode ? checkForMembership(item._id, 'plants') : false
                    const clickFunction = selectMode ? (isSelected ? () => removeFromSelection(item._id, "plants") : () => addToSelection(item._id, "plants")) : (onClick ? () => { console.log("triggered"); onClick(item) } : null)
                    return <Row user={user} selectMode={selectMode} index={index} isSelected={isSelected} overrides={overrides} onClick={clickFunction} key={index} item={item} keyArray={keyArray} selectableColumn={selectableColumn}></Row>
                })}
            </tbody>

        </table>
        {(!data || !data.length) && <EmptyTableBody />}
    </div>
}


const Table = ({ children, pdf }) => {

    const Wrapper = ({children}) => {
        if (pdf) return <div>{children}</div>
        return <Card className={styles.card}>{children}</Card>
    }

    return <Wrapper>
        <Grid.Container direction="column">
            {children.map((child, index) => {
                return <Grid direction="column" key={index} xs={24}>
                    {child}
                </Grid>
            })}
        </Grid.Container>
    </Wrapper>
}

Table.Toolbar = Toolbar
Table.Body = Body
Table.Footer = Footer

export default Table