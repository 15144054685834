const resolveURL = (url) => {

	// needed for non vercel deployments

	const firstPart = url.split("/")[0]
	const API_URL = "http://localhost:3000"

	// console.log(API_URL, process.env.SENTRY_DSN)

	if (!API_URL) throw new Error("API_URL not set")

	switch (firstPart) {
		case "":
			return API_URL + url
		default:
			return url
	}
}


const generateHeaders = (accessToken) => {
    return accessToken ? { authorization: `Bearer ${accessToken}` } : {}
}

const generateConfig = (url, method, data, accessToken) => {

    const config = {
        method,
        url: resolveURL(url),
        data, 
        headers: generateHeaders(accessToken)
    }

    return config

}


export { resolveURL, generateConfig }