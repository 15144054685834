
import styles from "./card.module.css"

const Card = ({ children, style, onClick, padding, className, id }) => {
    return <div id={id} style={{ padding: padding ? "30px" : null, width: "100%", ...style }} onClick={onClick} className={className ? styles.card + " " + className : styles.card}> {children}</div>
}

const Section = ({ children, title, pad, Icon }) => {
    return <div className={styles.section} style={{ padding: pad ? "0px 12px" : null }}>
        <div style={{ display: "flex", alignItems: 'center', gap: 8, marginBottom: "16px" }}>
            {Icon ? <Icon size={24}></Icon> : null}
            {title && <h4 style={{ margin: 0, fontWeight: 600, fontSize: "16px" }}>{title}</h4>}</div>
        {children}
    </div>
}

Card.Section = Section

export default Card;