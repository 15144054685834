import SubModalTitle from "https://framer.com/m/SubModalTitle-nQA3.js@58d3syuYiLTENE1ytEjq"
import ModalHeader from "https://framer.com/m/Modal-Header-iIue.js@CyxIjh2E6l5psv28iwYS"

import { Spacer } from '@geist-ui/core'
import { SecondaryButton } from '../../button'
import styles from './wrapper.module.css'

const Wrapper = ({ style, title, subtitle, icon, variant, children, hideDismiss, onClose, sub, onBack, secondaryButton, }) => {

    return <div className={styles?.container} style={style}>
        {sub ? <SubModalTitle icon={icon} tap={onBack} title={title}></SubModalTitle> :
            <ModalHeader
                title={title}
                subtitle={subtitle}
                icon={icon}
                variant={variant}
                style={{ width: "100%", marginBottom: "1.5em" }} />}
        {children}
        <Spacer h={0.5}></Spacer>
        {!hideDismiss && <SecondaryButton fullWidth onClick={onClose}>
            {secondaryButton ? secondaryButton : "Dismiss"}
        </SecondaryButton>}
    </div>
}

export default Wrapper