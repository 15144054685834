import { Grid, Spacer } from "@geist-ui/core"
import { useState } from "react"
import Loader from "../loader"
import Wrapper from "./wrapper"

const defaultSteps = {
    done: {
        title: 'Success',
        subtitle: "",
        variant: "done",
        View: () => {
            return ""
            // return <PrimaryButton onClick={() => router.push('/')}>View Workflow</PrimaryButton>
        }
    },
    error: {
        title: "Something went wrong",
        variant: "error",
        subtitle: "Please try again",
        View: ({ res }) => <>{res}</>
    },
    loading: {
        title: "Making stuff happen",
        View: Loader
    }
}

const getStep = (step, steps) => {

    if (!steps) throw new Error("No steps provided")

    const providedStepKeys = Object.keys(steps)
    if (providedStepKeys.includes(step)) return steps[step]

    const defaultStepKeys = Object.keys(defaultSteps)
    if (defaultStepKeys.includes(step)) return defaultSteps[step]

    else return defaultSteps.error
}


const PopupStepper = ({ steps, onClose, callback, initialStep, excludeIf, initialData, secondaryButton, hideDismiss, style }) => {

    const [step, setStep] = useState(initialStep ?? 'title')
    const [res, setRes] = useState()
    const [data, setData] = useState({
        title: "",
        type: "",
        ...initialData
    })

    const submit = async (throwError) => {
        const copiedStep = step
        setStep("loading")
        try {
            if (callback) await callback(data)
            setStep("done")
        } catch (e) {
            const { message, response } = e
            if (response) {
                const { data } = response
                if (data) {
                    if (data.message) {
                        setRes(data.message)
                    }
                }
            } else {
                setRes(message)
            }
            if (throwError) {
                setStep(copiedStep)
                throw e
            } else {
                setStep("error")
            }
        }
    }

    const next = async (e) => {
        if (e) e.preventDefault()
        try {
            if (steps[step].onComplete) {
                await steps[step].onComplete(setStep, submit, { data, setData })
            }
        } catch (err) {
            const { message, response } = err
            if (response) {
                const { data } = response
                if (data) {
                    if (data.message) {
                        setRes(data.message)
                    }
                }
            } else {
                setRes(message)
            }
            setStep("error")
        }

    }

    const updateData = (key, value) => {
        const copied = { ...data }
        copied[key] = value
        setData(copied)
    }

    const current = getStep(step, steps)
    const { View } = current

    return <Wrapper style={style} hideDismiss={hideDismiss} secondaryButton={secondaryButton} {...current} onBack={() => current?.onBack(setStep)} onClose={onClose}>
        <Spacer></Spacer>
        <Grid.Container direction="column">
            {View ? <View excludeIf={excludeIf} setData={setData} data={data} res={res} onChange={updateData} setStep={setStep} onComplete={next}></View> : "No View Provided"}
        </Grid.Container>
    </Wrapper>
}

export default PopupStepper